<template>
  <div class="full-page flex justify-center items-center w-screen h-screen">
    <div
      class="w-80 p-10 pb-20 bg-grayscale-10 rounded-20 self-center flex flex-col gap-8 items-center text-center"
    >
      <h1 class="text-5xl font-druk-wide font-medium">404</h1>
      <div class="flex flex-col gap-2">
        <p class="text-sm font-bold">Sorry something went wrong on our end</p>
        <p class="text-grayscale-80 text-sm">
          Please go back and try again or return to Xposure’s homepage.
        </p>
      </div>
      <router-link to="/" class="w-full md:w-auto">
        <p
          class="py-2.5 bg-accent-orange rounded-54 text-base text-grayscale-7 font-bold flex items-center justify-center px-8"
        >
          Home page
        </p>
      </router-link>
    </div>
  </div>
</template>

<style scoped>
.full-page {
  background-image: url("https://ik.imagekit.io/xposuremusic/404_bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
</style>
